<template>
    <v-card variant="text" class="mt-4 mx-auto">
        <v-card-text>
            <v-form v-model="form" @submit.prevent="next">
                <p class="text-center text-h5">{{ $t('app.Labels.DataAuthorization').toUpperCase() }}</p>
                <p class="my-4 text-justify">
                    <v-checkbox v-model="confirmation" color="indigo-darken-4">
                        <template v-slot:label>
                            <div>
                                {{ $t('app.Labels.DataConfirmation') }}
                                <v-tooltip location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <a :href="pdfUrl" target="_blank" v-bind="props" @click.stop>
                                            {{ $t('app.Labels.Link') }}
                                        </a>
                                    </template>
                                    {{ $t('app.Labels.ClickToViewPolicy') }}
                                </v-tooltip> .
                            </div>
                        </template>
                    </v-checkbox>
                </p>
                <v-card-actions>
                    <v-btn class="mt-2" color="orange-darken-1" variant="flat" @click="atras()"
                        :text="$t('app.Buttons.Back')"></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-2" color="success" :loading="loading" variant="flat" type="submit"
                        :disabled="!confirmation" :text="$t('app.Buttons.Save')">
                        <template v-slot:loader>
                            <v-progress-linear indeterminate></v-progress-linear>
                        </template>
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'AutorizacionDatos',
    data: () => ({
        loading: false,
        form: false,
        confirmation: false,
    }),
    computed: {
        pdfUrl() {
            const lang = this.$i18n.locale; // Obtiene el idioma actual
            return process.env.BASE_URL + `politica_datos_${lang.toUpperCase()}.pdf`;
        }
    },
    methods: {
        async next() {
            if (!this.form) return;
            this.loading = true;

            // Guardar resultado de la llamada a la acción
            const result = await this.$store.dispatch('guardarFormulario', this.medicacion);

            this.loading = false;

            // Verifica si la acción fue exitosa o no
            if (result.success) {
                this.$router.push('/success');
            } else {
                // Redirige a success_warning en caso de error
                this.$router.push('/success_warning');
            }
        },
        atras() {
            this.$store.commit('SET_VENTANA', 4);
        }
    }
}
</script>
